const colors = {
  '爆款': {
    color: '#da0d68',
    children: {
      '活动': {
        color: '#a87b64',
        children: {
          '活动报名': {
            color: '#c78869'
          },
          '素材准备': {
            color: '#d4ad12'
          },
          '玩法报名': {
            color: '#9d5433'
          },
          '活动复盘': {
            color: '#c89f83'
          }
        }
      },
      '内功': {
        color: '#e0719c',
        children: {
          '标题优化': {
            color: '#f99e1c'
          },
          '晒图维护': {
            color: '#ef5a78'
          },
          '问大家维护': {
            color: '#f7f1bd'
          },
          '外包买家秀': {
            color: '#692a19'
          },
          'AA单维护': {
            color: '#470604'
          }
        }
      },
      '补单': {
        color: '#975e6d',
        children: {
          '选词': {
            color: '#bb764c'
          }
        }
      },
      '视觉': {
        color: '#ad213e',
        children: {
          '图片': {
            color: '#794752'
          },
          '视频': {
            color: '#cc3d41'
          }
        }
      },
      '广告': {
        color: '#b14d57',
        children: {
          '直通车': {
            color: '#c78936'
          },
          '超级推荐': {
            label: '超级推荐'
          },
          '钻展': {
            color: '#e5762e'
          },
          '品销宝': {
            color: '#a16c5a'
          }
        }
      },
      '财务报销': {
        color: '#c94930',
        children: {
          '补单报销': {
            color: '#caa465'
          },
          'AA单报销': {
            color: '#dfbd7e'
          },
          '购买软件报销': {
            color: '#ddaf61'
          },
          '平台充值报销': {
            color: '#b7906f'
          },
          '外包买家秀报销': {
            color: '#eb9d5f'
          },
          '外包美工作图报销': {
            color: '#e75b68'
          },
          '其他报销': {
            color: '#d0545f'
          }
        }
      },
      '其他': {
        color: '#B36619',
        children: {
          '其他': {
            color: '#bb764c'
          }
        }
      }
    }
  },
  '动销': {
    color: '#da1d23',
    children: {
      '活动': {
        color: '#e85084',
        children: {
          '活动报名': {
            color: '#3e0317'
          },
          '素材准备': {
            color: '#e62969'
          },
          '玩法报名': {
            color: '#6569b0'
          },
          '活动复盘': {
            color: '#ef2d36'
          }
        }
      },
      '内功': {
        color: '#dd4c51',
        children: {
          '标题优化': {
            color: '#f2684b'
          },
          '晒图维护': {
            color: '#e73451'
          },
          '问大家维护': {
            color: '#ff5b4e'
          },
          '外包买家秀': {
            color: '#f89a1c'
          },
          'AA单维护': {
            color: '#aeb92c'
          }
        }
      },
      '补单': {
        color: '#4eb849',
        children: {
          '选词': {
            color: '#f68a5c'
          }
        }
      },
      '视觉': {
        color: '#c94a44',
        children: {
          '图片': {
            color: '#b53b54'
          },
          '视频': {
            color: '#a5446f'
          }
        }
      },
      '广告': {
        color: '#be8663',
        children: {
          '直通车': {
            color: '#b9a449'
          },
          '超级推荐': {
            color: '#899893'
          },
          '钻展': {
            color: '#a1743b'
          },
          '品销宝': {
            color: '#894810'
          }
        }
      },
      '财务报销': {
        color: '#d45a59',
        children: {
          '补单报销': {
            color: '#310d0f'
          },
          'AA单报销': {
            color: '#ae341f'
          },
          '购买软件报销': {
            color: '#d78823'
          },
          '平台充值报销': {
            color: '#da5c1f'
          },
          '外包买家秀报销': {
            color: '#f89a80'
          },
          '外包美工作图报销': {
            color: '#f37674'
          },
          '其他报销': {
            color: '#e65832'
          }
        }
      },
      '其他': {
        color: '#B38519',
        children: {
          '其他': {
            color: '#B3A319'
          }
        }
      }
    }
  },
  '平销': {
    color: '#ebb40f',
    children: {
      '活动': {
        color: '#b09733',
        children: {
          '活动报名': {
            color: '#8f1c53'
          },
          '素材准备': {
            color: '#b34039'
          },
          '玩法报名': {
            color: '#ba9232'
          },
          '活动复盘': {
            color: '#8b6439'
          }
        }
      },
      '内功': {
        color: '#f7a128',
        children: {
          '标题优化': {
            color: '#f26355'
          },
          '晒图维护': {
            color: '#e2631e'
          },
          '问大家维护': {
            color: '#fde404'
          },
          '外包买家秀': {
            color: '#7eb138'
          },
          'AA单维护': {
            color: '#baa635'
          }
        }
      },
      '补单': {
        color: '#faef07',
        children: {
          '选词': {
            color: '#c1ba07'
          }
        }
      },
      '视觉': {
        color: '#9d977f',
        children: {
          '图片': {
            color: '#cc7b6a'
          },
          '视频': {
            color: '#db646a'
          }
        }
      },
      '广告': {
        color: '#e1c315',
        children: {
          '直通车': {
            color: '#9ea718'
          },
          '超级推荐': {
            color: '#94a76f'
          },
          '钻展': {
            color: '#d0b24f'
          },
          '品销宝': {
            color: '#8eb646'
          }
        }
      },
      '财务报销': {
        color: '#3aa255',
        children: {
          '补单报销': {
            color: '#a2bb2b'
          },
          'AA单报销': {
            color: '#62aa3c'
          },
          '购买软件报销': {
            color: '#03a653'
          },
          '平台充值报销': {
            color: '#038549'
          },
          '外包买家秀报销': {
            color: '#28b44b'
          },
          '外包美工作图报销': {
            color: '#a3a830'
          },
          '其他报销': {
            color: '#7ac141'
          }
        }
      },
      '其他': {
        color: '#A3B319',
        children: {
          '其他': {
            color: '#85B319'
          }
        }
      }
    }
  },
  '竞品': {
    color: '#187a2f',
    children: {
      '竞品流量分析': {
        color: '#a2b029'
      },
      '选品数据分析': {
        color: '#718933'
      },
      '其他': {
        color: '#66B319',
        children: {
          '其他': {
            color: '#47B319'
          }
        }
      }
    }
  },
  '全店': {
    color: '#0aa3b5',
    children: {
      '报表填写': {
        color: '#9db2b7',
        children: {
          '重点款预测表': {
            color: '#8b8c90'
          },
          '推广报表预估': {
            color: '#beb276'
          },
          '店铺库存预估': {
            color: '#fefef4'
          }
        }
      },
      '大促': {
        color: '#76c0cb',
        children: {
          '活动报名': {
            color: '#80a89d'
          },
          '素材准备': {
            color: '#b2e4ff'
          },
          '玩法报名': {
            color: '#7a9bae'
          },
          '活动复盘': {
            color: '#039fb8'
          }
        }
      },
      '其他': {
        color: '#29B319',
        children: {
          '其他': {
            color: '#19B329'
          }
        }
      }
    }
  },
  '其他': {
    color: '#B32919',
    children: {
      '其他': {
        color: '#863E2D',
        children: {
          '其他': {
            color: '#B34719'
          }
        }
      }
    }
  }
}

export default colors
