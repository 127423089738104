<template>
  <div class="sunburst-charts-container">
    <div ref="sunburstChart" class="chart" style="height: 900px;width: 100%"></div>
  </div>
</template>
<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import colors from '@/data/color.js'

export default {
  name: 'SunburstCharts',
  mixins: [resize],
  props: {
    chartData: {
      type: Array,
      default: () => { return [] }
    },
    chartHeader: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData(val) {
      const formatterData = this.dataFormatter(val)
      this.resetChartsData(formatterData)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts() {
      this.chart = echarts.init(this.$refs.sunburstChart, 'macarons')
      this.setChartsOption()
    },
    setChartsOption() {
      const data = this.dataFormatter(this.chartData) || []
      this.chart.setOption({
        // downplay: {
        //     label: {
        //         formatter: '{b} - {c}min'
        //     }
        // },
        // highlight: {
        //     label: {
        //         formatter: '{b} - {c}min'
        //     }
        // },
        label: {
          formatter: '{b} - {c}min'
        },
        series: [{
          type: 'sunburst',
          id: 'monthSunburstCharts',
          // name: 'monthSunburstCharts',
          data: data,
          highlightPolicy: 'ancestor',
          levels: [{}, {
            r0: '20%',
            r: '35%',
            itemStyle: {
              borderWidth: 2
            },
            label: {
              rotate: 'tangential'
              // formatter: '{b} - {c}min'
            }
          }, {
            r0: '35%',
            r: '60%',
            label: {
              align: 'center',
              rotate: 'tangential'
              // formatter: '{b} - {c}min'
            }
          }, {
            r0: '60%',
            r: '65%',
            label: {
              position: 'outside',
              backgroundColor: 'rgba(0,0,0,0.2)',
              padding: 5,
              borderRadius: 3
            },
            itemStyle: {
              borderWidth: 3
            }
          }]
        }]
      })
    },
    resetChartsData(data) {
      this.chart.setOption({
        series: [{
          // name: 'monthSunburstCharts',
          data: data
        }]
      })
    },
    dataFormatter(val) {
      const jsonData = this.formatterArrayToJson(val)
      const treeData = this.formatterJsonToTree(jsonData, colors)
      return treeData
    },
    /**
         * 一位数组转为json对象
         */
    formatterArrayToJson(array) {
      const treeJsonData = {}
      array.forEach(item => {
        if (item['三级模块'] && item['三级模块'] !== '') {
          if (treeJsonData[item['一级模块']]) {
            if (treeJsonData[item['一级模块']][item['二级模块']]) {
              if (treeJsonData[item['一级模块']][item['二级模块']][item['三级模块']] || treeJsonData[item['一级模块']][item['二级模块']][item['三级模块']] === 0) {
                treeJsonData[item['一级模块']][item['二级模块']][item['三级模块']] += item['花费时间/分钟']
              } else {
                treeJsonData[item['一级模块']][item['二级模块']][item['三级模块']] = item['花费时间/分钟']
              }
            } else {
              treeJsonData[item['一级模块']][item['二级模块']] = {
                [item['三级模块']]: item['花费时间/分钟']
              }
            }
          } else {
            treeJsonData[item['一级模块']] = {
              [item['二级模块']]: {
                [item['三级模块']]: item['花费时间/分钟']
              }
            }
          }
        } else {
          if (treeJsonData[item['一级模块']]) {
            if (treeJsonData[item['一级模块']][item['二级模块']] || treeJsonData[item['一级模块']][item['二级模块']] === 0) {
              treeJsonData[item['一级模块']][item['二级模块']] += (item['花费时间/分钟'] || 0)
            } else {
              treeJsonData[item['一级模块']] = {
                [item['二级模块']]: item['花费时间/分钟'] || 0
              }
            }
          } else {
            treeJsonData[item['一级模块']] = {
              [item['二级模块']]: item['花费时间/分钟'] || 0
            }
          }
        }
      })
      return treeJsonData
    },
    /**
         * json对象转为charts需要的树形对象
         */
    formatterJsonToTree(jsonObj, colors) {
      const treeObj = []
      for (const treeKey in jsonObj) {
        if (typeof jsonObj[treeKey] === 'object') {
          treeObj.push({
            name: treeKey,
            itemStyle: {
              color: colors[treeKey].color
            },
            children: this.formatterJsonToTree(jsonObj[treeKey], colors[treeKey].children)
          })
        } else {
          treeObj.push({
            name: treeKey,
            itemStyle: {
              color: colors[treeKey].color
            },
            value: jsonObj[treeKey]
          })
        }
      }
      return treeObj
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
