<template>
  <div class="app-container">
    <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
    <el-tabs v-model="tabsActiveName" @tab-click="onTabClick">
      <el-tab-pane label="数据表格" name="table">
        <el-table :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;">
          <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane label="数据折线图" name="line-charts">
                <line-charts ref="lineCharts" :chart-data="lineChartData"></line-charts>
            </el-tab-pane>
            <el-tab-pane label="数据柱形图" name="bar-charts">数据柱形图</el-tab-pane>
            <el-tab-pane label="数据饼图" name="pie-charts">
                <pie-charts ref="pieCharts" :chart-data="tableData" :chart-header="tableHeader" />
            </el-tab-pane> -->
      <el-tab-pane label="旭日图" name="sunburst-charts">
        <sunburst-charts ref="sunburstCharts" :chart-data="tableData" :chart-header="tableHeader" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UploadExcelComponent from '@/components/UploadExcel/index.vue'
// import LineCharts from './components/LineCharts'
// import PieCharts from './components/PieCharts'
import SunburstCharts from './components/SunburstCharts'

export default {
  name: 'UploadExcel',
  components: {
    UploadExcelComponent,
    // LineCharts,
    // PieCharts,
    SunburstCharts
  },
  data() {
    return {
      tabsActiveName: 'table',
      tableData: [],
      tableHeader: [],
      lineChartData: {
        expectedData: [100, 120, 161, 134, 105, 160, 165],
        actualData: [120, 82, 91, 154, 162, 140, 145]
      }
    }
  },
  methods: {
    onTabClick(tab, event) {
      const tabName = tab.name
      if (tabName === 'line-charts') {
        this.$refs.lineCharts.$_resizeHandler()
      } else if (tabName === 'pie-charts') {
        this.$refs.pieCharts.$_resizeHandler()
      } else if (tabName === 'sunburst-charts') {
        this.$refs.sunburstCharts.$_resizeHandler()
      }
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1

      if (isLt1M) {
        return true
      }

      this.$message({
        message: '请选择小于1M的excel文件',
        type: 'warning'
      })
      return false
    },
    handleSuccess({ results, header }) {
      this.tableData = results
      this.tableHeader = header
    }
  }
}
</script>
